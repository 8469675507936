














import Vue from "vue";
import Component from "vue-class-component";   
import SignInForm from "@/vue/components/SignInForm.vue";

@Component({
    components: { SignInForm }
})
export default class SignInDialogue extends Vue {

    dialogVisible: boolean = false;

    show() {           
        this.dialogVisible = true;
    }

    hide() {
        this.dialogVisible = false;
    }

    onAuthenticated() {
        console.log("...SignInDialogue component - onAuthenticated");
        this.hide();
        // just bubble up after hiding self
        this.$emit("is-authenticated");
    }
}
