







import Vue from "vue";
import Component from "vue-class-component";
import eventBus from "@/utilities/EventBus";
import apiClient, { ISendRequestParameters } from "@/utilities/ApiClient";
import * as toastr from "toastr";

const defaultLayout = "visitor";

import SignInDialogue from "@/vue/components/SignInDialogue.vue";

@Component({
    components: { SignInDialogue }
})
export default class App extends Vue {

    private pendingApiParameters: ISendRequestParameters | null = null;

    mounted() {

        // Prevent any XSS shenanigans by default
        toastr.options.escapeHtml = true;

        eventBus.$on("http-401", (parameters: ISendRequestParameters) => {
            this.pendingApiParameters = parameters;
            console.log("...App component - setting promise and showing login dialogue");
            const dlg: SignInDialogue = this.$refs.signInDialogue as SignInDialogue;
            dlg.show();
        });

        eventBus.$on("http-error", (response: Response) => {
            const status = response.status;
            if (status === 500) {
                response.text().then(bodyText => {
                    // if we're running in production, we'll probably not get a response body - but handy to show in dev mode
                    if (bodyText) {
                        this.showErrorMessageWithContent("Server Error", "Status = " + response.status, bodyText);
                    }
                    else {
                        this.showErrorMessage("Server Error", "Status = " + response.status);
                    }                       
                });
            }
            else {
                this.showErrorMessage("HTTP Error", "Status = " + response.status);
            }
        });

        eventBus.$on("fetch-exception", (error: Error) => {
            // TODO what if error is null or message is null
            this.showErrorMessage("API Error", error.message);
        });
    }

    onAuthenticated() {
        console.log("...App component - onAuthenticated");
        if (this.pendingApiParameters == null) {
            console.log("...App component - onAuthenticated - pendingApiParameters is **NULL**!");
            // what should we do if we ever get here?!!
            return;
        }            
        console.log("...App component - resend last API request");
        apiClient.sendRequest(this.pendingApiParameters);
        this.pendingApiParameters = null;
    }

    // todo - how do we show a dialogue via code with vuetify
    showErrorMessageWithContent(title: string, message: string, content: string) {
        toastr.warning(message, title);
    }

    showErrorMessage(title: string, message: string) {
        toastr.warning(message, title);
    }

    // computed property used to select layout - see also main.ts
    get layout() {
        if (!this.$route.meta?.layout) return null;
        return (this.$route.meta?.layout || defaultLayout) + "-layout";
    }
}
