import Vue from "vue";
import Vuetify, { UserVuetifyPreset } from "vuetify";
import InfiniteLoading from "vue-infinite-loading";

import App from "@/vue/App.vue";
import router from "@/router/router";
import store from "@/store/store";

import "@/utilities/CustomFilters";

// CSS

import "@fontsource/roboto";
import "@mdi/font/css/materialdesignicons.css"
import "toastr/build/toastr.css"; 
import "vuetify/dist/vuetify.css";
import "@/css/site.scss";

// https://peachscript.github.io/vue-infinite-loading/guide/configure-plugin-opts.html#props-settings
Vue.use(InfiniteLoading, {
    props: { spinner: "spiral" },
    slots: { noMore: "", noResults: ""  }
});

import Blank from "@/vue/layouts/Blank.vue";
import SignedIn from "@/vue/layouts/SignedIn.vue";
import dirtyness from "@/utilities/Dirtyness";

Vue.component("blank-layout", Blank);
Vue.component("signed-in-layout", SignedIn);

Vue.config.productionTip = false;

// TODO
// https://www.raymondcamden.com/2019/05/01/handling-errors-in-vuejs
// https://stackoverflow.com/questions/52071212/how-to-implement-global-error-handling-in-vue

if(process.env.NODE_ENV !== "development") {
    Vue.config.errorHandler = (error: Error, vm: Vue, info: string) => {
        console.log("@@@@ Vue.config.errorHandler: " + error.toString() + " info:" + info);
        console.error(error);
        console.log("@@@@ stack:");
        console.log(error?.stack);
        
    };
    Vue.config.warnHandler = (message: string, vm: Vue, trace: string) => {
        console.log("@@@@ Vue.config.warnHandler: " + message + " trace:" + trace);
    };

    window.onerror = (message: string | Event, source: string | undefined, line: number | undefined, column: number | undefined, error: Error | undefined) => {
        console.log("@@@@ window.onerror: " + message);
        console.error(error);
        console.log("@@@ stack:");
        console.log(error?.stack);
    };
}
    
// dirty-check - leaving page
window.addEventListener("beforeunload", (event?: Event) => {
    if(!event) {
        event = window.event;
    }
    if (dirtyness.isDirty) {
        if(event) {
            event.preventDefault();
            event.returnValue = true;
        }
        // I don't think messages like this are supported in modern browsers but...
        return "It looks like you have not saved your changes."; 
    }
    else {
        if(event){
            // From MDN docs: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete (event as any)["returnValue"];
        }
        // no real point in calling reset() since we're leaving the site but might as well...
        dirtyness.reset(); 
        return undefined;
    }
});

//
// -- vuetify
//

Vue.use(Vuetify);
const opts: Partial<UserVuetifyPreset> = {};
const vuetify: Vuetify = new Vuetify(opts);

//
// -- Mount the application!
//

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount("#app");