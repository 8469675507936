








































import Vue from "vue";
import Component from "vue-class-component";
import SlideUpDown from "vue-slide-up-down";
import { Ref } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";
import { User, IUser } from "@/model/User";
import authentication from "@/utilities/Authentication";
import apiClient from "@/utilities/ApiClient";
import { VForm } from "@/vForm";

// We could put these interfaces (or classes) in their own files - but they're small 
// and so far they're only used here.

interface ISignInRequest {
    username: string;
    password: string;
}

export interface ISignInResponse {
    isSuccess: boolean;
    message: string;
    user: IUser;
}

interface IForgotRequest {
    username: string;
}

interface IForgotResponse {
    isSuccess: boolean;
    message: string;
}

// https://vuejs.org/v2/guide/typescript.html

@Component({
    components: { SlideUpDown, ApiButton }
})     
export default class SignInForm extends Vue {

    // Lifecycle hook
    mounted() {
        this.loadFromStorage()
    }

    @Ref("signInForm") private readonly signInForm!: VForm;
    @Ref("forgotPasswordForm") private readonly forgotPasswordForm!: VForm;

    signInRequest: ISignInRequest = { username: "", password: "" };
    forgotRequest: IForgotRequest = { username: "" };

    isForgotMode = false;
    messageText = "";
    messageType = "error"

    get isMessage(): boolean {
        return !!this.messageText;
    }

    // get properties are 'computeds'
    get isAuthenticated(): boolean {
        return this.$store.state.signedInUser !== null;
    }

    get userName(): string {
        const user = this.$store.state.signedInUser as User;
        if (user === null) return "not logged in";
        return `${user.forename} ${user.surname}`;
    }

    toggleMode() {
        this.signInForm.resetValidation();
        this.forgotPasswordForm.resetValidation();
        this.messageText = "";
        this.messageType = "error";
        if (!this.isForgotMode && this.signInRequest.username) {
            // copy the username over to save user typing it out again
            this.forgotRequest.username = this.signInRequest.username;
        }
        this.isForgotMode = !this.isForgotMode;
    }

    async signIn() {
        // TODO - if this triggered from enter key, we could do with finding anad passing the button...
        if (!this.signInForm.validate()) {
            this.messageText = "Please enter your username address and password!";
            return;
        }

        const response: ISignInResponse = await apiClient.post("/Api/Authentication/signin", this.signInRequest, "sign-in");
        await authentication.signIn(new User(response.user));

        if (this.isAuthenticated) {
            this.saveToStorage(response.user);
            console.log("...SignInForm component - emitting is-authenticated");
            this.$emit("is-authenticated");
        }
        else {
            this.messageText = response.message;
        }
    }

    async submitForgot() {
        if (!this.forgotPasswordForm.validate()) return;

        const response: IForgotResponse = await apiClient.post("/Api/Authentication/forgot", this.forgotRequest, "submit-forgot");
        this.messageText = response.message;
        this.messageType = response.isSuccess ? "success" : "error"
    }

    private loadFromStorage() {
        if (typeof (Storage) === "undefined") return;
        const userJson = localStorage.getItem("user");
        if (!userJson) return;
        try {
            const user = JSON.parse(userJson);
            this.signInRequest.username = (user && user.username) ? user.username : "";
            this.forgotRequest.username = this.signInRequest.username;
        } catch (e) {
            this.signInRequest.username = "";
        }
    }

    private saveToStorage(user: IUser) {
        if (typeof (Storage) === "undefined") return;
        localStorage.setItem("user", JSON.stringify(user));
    }
    
}
