

























































































import Vue from "vue";
import Component from "vue-class-component";
import authentication from "@/utilities/Authentication";

@Component({})
export default class SignedInLayout extends Vue {

    //
    // -- lifecycle hooks
    //

    mounted() {
        document.body.style.backgroundColor = "#fff";
    }

    //
    // -- properties
    //

    drawerOpen: boolean|null = null

    //
    // -- methods
    //

    signOut() {
        // Not using async / await here so we'll just trust the sign out occurs in the background
        // and rely on error handler if anything screws up.
        authentication.signOut();

        this.$router.push("/");
    }

}
