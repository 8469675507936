import Vue from "vue";
import utils from "@/utilities/Utils";

//
// -- booleans
//

Vue.filter("yesNoText", (value: boolean): string => {
    return value ? "Yes" : "No";
});

//
// -- dates
//

Vue.filter("dateText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateText(value);
});

Vue.filter("dateTimeText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.dateTimeText(value);
});

Vue.filter("whenText", (value: Date): string => {
    if (!value) return "- - -";
    return utils.whenText(value);
});

//
// -- numbers
//

Vue.filter("currencyAmount", (value: number): string => {
    //return !isNaN(value) && value.toLocaleString(undefined, {maximumFractionDigits:2}) || "0.00";
    return !isNaN(value) ? utils.toMoney(value) : "£0.00";
});