// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    None = 0,
    Admin = 1,
    Basic = 2,
    Resident = 3
}

export enum UserStatus {
    None = 0,
    AwaitingActivation = 1,
    Active = 2,
    Disabled = 3
}

export enum LookupGroup {
    All = 0,
    RepairType = 13
}

export enum AccountType {
    Rent = 25,
    PitchFee = 26,
    Water = 27,
    Electric = 28
}

export enum PaymentStage {
    None = 0,
    ChooseAccounts = 1,
    AdditionalProducts = 2,
    ReviewAndPay = 3
}