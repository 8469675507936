import store from "@/store/store";
import utils from "@/utilities/Utils";
import { User } from "@/model/User";

module Authentication {

    export function isAuthenticated(): boolean { 
        return store.state.signedInUser !== null;
    }

    export function signedInUser(): User | null {
        return store.state.signedInUser;
    }

    // For JWT (JSON web token) 
    export function apiBearerToken(): string {
        const user = store.state.signedInUser;
        if (!user) return "";
        return `Bearer ${user.jwtBearerToken}`;
    }

    export async function signOut() {
        await store.dispatch("setSignedInUser", null);
        sessionStorage.removeItem("signedInUser");
        console.log("Authentication - signed out");
    }

    export async function signIn(user: User) {
        if (user && !utils.isEmptyId(user.id)) {
            // store in Vuex store - also sets session storage
            await store.dispatch("setSignedInUser", user);
        } else {
            await signOut();
        }
    }

    export async function onHttpUnauthorised() {
        // see eventBus - http-401 is dealt with handler in App component...
        await store.dispatch("setSignedInUser", null);
    }
}

export default Authentication;