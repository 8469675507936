import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";
import { LookupGroup } from "@/model/Enums";

export interface ILookupItem {
    id: number|string;
    group: LookupGroup;
    description: string;
    displayOrder: number
}

export class LookupItem implements ILookupItem {
    constructor(data?: ILookupItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupItem) {
        mapData(data, { root: () => this });
    }

    id: number|string = utils.emptyGuidValue;
    group: LookupGroup = LookupGroup.All;
    description: string = "";
    displayOrder: number = 0;

    static createLookup(id: number, description: string): LookupItem {
        return new LookupItem({
            id: id,
            group: LookupGroup.All,
            description: description,
            displayOrder: 0
        });
    }
    
}