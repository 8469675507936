import utils from "@/utilities/Utils";
import { UserRole, UserStatus } from "@/model/Enums";
import { mapData } from "@/utilities/DataMapping";

export interface IUser {
    id: string;
    status: UserStatus;
    role: UserRole;
    userName: string;
    forename: string;
    surname: string;
    email: string;
    lastLoggedIn: Date;
    jwtBearerToken: string;
}

export class User implements IUser {

    constructor(data?: IUser) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IUser) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    status: UserStatus = UserStatus.None;
    role: UserRole = UserRole.None;
    userName: string = "";
    forename: string = "";
    surname: string = "";
    email: string = "";
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    jwtBearerToken: string = "";

    get fullname() {
        return this.forename + " " + this.surname;
    }
    
}